import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TemplatesService } from 'src/app/core/http/templates/templates.service';

@Component({
  selector: 'app-btn-download-url',
  templateUrl: './btn-download-url.component.html',
  styleUrls: ['./btn-download-url.component.scss']
})
export class BtnDownloadUrlComponent implements OnInit {

  @Input() url: string;
  @Input() buttonText: string;
  downloading = false;
  errorMessage = '';

  constructor(private onChange: ChangeDetectorRef, private templatesService: TemplatesService) { }

  ngOnInit() {
  }

  download(event: Event) {
    event.preventDefault();
    this.downloading = true;

    try {
      this.templatesService.downloadFileUrl(this.url);
      this.downloading = false;

    } catch (error) {
      this.errorMessage = 'Error downloading the certificate';
      this.downloading = false;
      this.onChange.detectChanges();
    }
    // // this.templatesService.downloadFileUrl(url);
    // fetch(this.url)
    //   .then(response => {
    //     if (!response.ok) {
    //       throw new Error(response.statusText);
    //     }
    //     const urlParts = this.url.split("/");
    //     const filename = urlParts[urlParts.length - 1];
    //     return { blob: response.blob(), filename };
    //   })
    //   .then(({ blob, filename }: { blob: any, filename: string }) => {
    //     debugger
    //     const url = window.URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.href = url;
    //     a.download = filename;
    //     document.body.appendChild(a);
    //     a.click();
    //     a.remove();
    //     this.downloading = false;
    //     this.onChange.detectChanges();

    //   })
    //   .catch(error => {
    //     console.error('Error downloading the certificate:', error);
    //     this.errorMessage = 'Error downloading the certificate';
    //     this.downloading = false;
    //     this.onChange.detectChanges();

    //   });
  }

  clearError() {
    this.errorMessage = '';
  }

}
