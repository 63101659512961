import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

export enum StarRatingColor {
  primary = "primary",
  accent = "accent",
  warn = "warn"
}

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent implements OnInit, OnChanges {
  @Input('rating') rating: number = 0;
  @Input('starCount') starCount: number = 5;
  @Input('color') color: StarRatingColor = StarRatingColor.accent;
  @Input() readOnly: boolean = false;
  @Output() ratingUpdated = new EventEmitter();

  ratingArr = [];

  constructor() {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.starCount && changes.starCount.currentValue != changes.starCount.previousValue) {
      this.refreshRatingArray();
    }
  }


  ngOnInit() {

  }
  refreshRatingArray() {
    this.ratingArr = [];
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
  }
  onClick(rating: number) {
    if (this.readOnly){
      return;
    }
    this.ratingUpdated.emit(rating);
  }

  showIcon(index: number) {
    if (this.rating >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }

}

