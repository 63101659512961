import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProvincesInterface, ProvincesListResource } from '../../interfaces/provinces.resource';
import { AuthorizationService } from '../authorization.service';


@Injectable({
  providedIn: 'root'
})
export class ProvincesService {

  private resource: string = 'provinces';

  constructor(
    private http: AuthorizationService
  ) { }

  findAll(filters: Object = {}, sort: string = 'name', order: string = 'asc', pageNumber: number, pageSize: number = 1000) {
    return this.http.get(`/${this.resource}` + this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize));
  }

  findOne(id: number) {
    return this.http.get(`/${this.resource}/` + id);
  }

  getForSelection() {
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString({}, 'name', 'asc', 0, 5000));
  }

  getAllForSelection() {
    return this.http.get(`/${this.resource}/all`, this.http.buildListQueryString({}, 'Provinces.name', 'asc', 0, 5000));
  }

  /**
* Find all for select input.
*
* @returns
*/
  findAllForSelection(filters = {}): Observable<ProvincesListResource> {
    return this.http.get(`/${this.resource}/all` + this.http.buildListQueryString(filters, 'Provinces.name', 'asc', 0, 1000));
  }
}
