export enum StatusCollection {
    active = 1,
    inactive = 2,
    pending = 3,
    accepted = 4,
    rejected = 5,
    deleted = 6,
    processing = 7,
    unused = 8,
    used = 9,
    delivered = 10,
    draft = 11,
    scheduled = 12,
    sending = 13,
    sended = 14,
    cancelled = 15,
    new = 16,
    unreaded = 17,
    readed =18
};