import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { environment } from "../../../../environments/environment";
import BeePlugin from "@mailupinc/bee-plugin";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TemplatesService } from 'src/app/core/http/notifications/templates.service';

@Component({
  selector: 'app-template-editor-dialog',
  templateUrl: './template-editor-dialog.component.html',
  styleUrls: ['./template-editor-dialog.component.scss']
})
export class TemplateEditorDialogComponent implements OnInit {

  @Output() editorEmitter = new EventEmitter();

  mergeTags = [{
    name: 'ID del usuario',
    value: '{{id}}'
  }, {
    name: 'Nombre del usuario',
    value: '{{name}}'
  }, {
    name: 'Apellidos del usuario',
    value: '{{surname}}'
  }, {
    name: 'Email del usuario',
    value: '{{email}}'
  }, {
    name: 'Baja de comunicaciones',
    value: '{{unsubscribeLink}}'
  }, {
    name: 'Pines',
    value: '{{pins}}'
  }];

  BEE_TEMPLATE_URL: string = 'https://rsrc.getbee.io/api/templates/one-column';

  constructor(
    public dialogRef: MatDialogRef<TemplateEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private templatesService: TemplatesService
  ) { }

  ngOnInit(): void {
    this.loadEditor();
  }

  loadEditor(): void {
    const beeConfig = {
      uid: "beeplugin",
      container: 'bee-plugin-container',
      autosave: 3,
      language: 'es-ES',
      trackChanges: true,
      mergeTags: this.mergeTags,
      onSave: (pageJson: string, pageHtml: string) => {
        const data = { json: pageJson, html: pageHtml };
        this.close(data);
      }
    };

    const beeEditor = new BeePlugin();

    const token = {
      CLIENT_ID: environment.beePlugin.cliend_id,
      CLIENT_SECRET: environment.beePlugin.client_secret
    }

    beeEditor.getToken(token.CLIENT_ID, token.CLIENT_SECRET)
      .then(() => {
        return fetch(this.BEE_TEMPLATE_URL)
          .then((response: any) => response.json())
      })
      .then((response) => {
        if (this.data.json) {
          beeEditor.start(beeConfig, this.data.json);
        } else {
          this.templatesService.findDefault().subscribe(res => {
            beeEditor.start(beeConfig, res.data.attributes.template);
          }, error => {
            beeEditor.start(beeConfig, response);
            console.log(error);
          });
        }
      });
  }

  close(result: any) {
    this.dialogRef.close(result);
  }
}