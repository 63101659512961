import { AfterViewInit, Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-products-points',
  templateUrl: './products-points.component.html',
  styleUrls: ['./products-points.component.css']
})
export class ProductsPointsComponent implements OnInit {


  error = false;

  @Input() points: number = 0;

  @Output() valueChanges: EventEmitter<any> = new EventEmitter();


  constructor(
    public dialogRef: MatDialogRef<ProductsPointsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  /**
   * On view init
   *
   * @memberof ProductsPointsComponent
   */
  ngOnInit(): void {
    
  }


  /**
   * Close the dialog and send the decision to the opener.
   *
   * @param {*} result
   * @memberof ProductsPointsComponent
   */
   close(value) {
     if(value){
      if(this.points){
        this.dialogRef.close(this.points);
      }else{
        this.error = true;
      }
     }else{
      this.dialogRef.close(null);
     }
  }


}
