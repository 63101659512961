import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthorizationService } from '../authorization.service';

@Injectable({
  providedIn: 'root'
})
export class RegionsService {

  
  /**
   * Api resource
   *
   * @private
   * @type {string}
   */
  private resource: string = 'regions';

  /**
   * Creates an instance of RolesService.
   * @param {AuthorizationService} http
   */
  constructor(private http: AuthorizationService) { }

  /**
   * List items.
   *
   * @returns
   */
  findAll(filters: Object = {}, sort: string = 'Regions.name', order: string = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<any> {
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize));
  }


  /**
   * Find all for select input.
   *
   * @returns
   */
  findAllForSelection(): Observable<any> {
    return this.http.get(`/${this.resource}`, '?fields[roles]=id,name&sort=Regions.name&page[size]=1000');
  }


  /**
   * Find an item.
   *
   * @param {number} id
   * @returns
   */
  findOne(id: number): Observable<any> {
    return this.http.get(`/${this.resource}/` + id);
  }

  /**
   * Stores an item
   *
   * @param {Role} data
   * @returns
   */
  store(data: any): Observable<any> {
    if (data.id) {
      return this.http.put(`/${this.resource}/` + data.id, data);
    } else {
      return this.http.post(`/${this.resource}`, data);
    }
  }

  /**
   * Delete one or multiple items.
   * 
   * @param ids 
   */
  delete(ids): Observable<any> {
    return this.http.delete(`/${this.resource}/${ids.toString()}`);
  }
}
