import { Injectable } from '@angular/core';
import { AuthorizationService } from '../authorization.service';
import { Observable } from 'rxjs';
import { UsersListResource, UserInterface, UserResource, UsersListResourceModel } from 'src/app/core/interfaces/users.resource';
import { ScopesCollection } from '../../collections/scopes.collection';
import { StatusCollection } from '../../collections/status.collection';
import { map } from 'rxjs/operators';

export interface User {
  id: number,
  name: string,
  surname: string,
  email: string,
  password: string,
  role_id: number,
  status_id: number,
  points: number,
  auth_code: string,
  zone: string,
}

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private resource: string = 'users';

  /**
   *Creates an instance of RolesService.
   * @param {AuthorizationService} http
   * @memberof RolesService
   */
  constructor(private http: AuthorizationService) { }

  /**
   * Find all items.
   *
   * @returns
   * @memberof RolesService
   */
  findAll(filters: Object = {}, sort: string = 'Users.name', order: string = 'asc', pageNumber: number = 0, pageSize: number = 10, include: string = 'roles,status,users_types,locations,commercial,registration_commercial,manager'): Observable<UsersListResource> {
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize) + '&include=' + include)
      .pipe(
        map(users => {
          return UsersListResourceModel.fromJson(users);
        })
      );
  }

  /**
   * Find all items.
   *
   * @returns
   * @memberof RolesService
   */
  findAllAsString(filters: Object = {}, sort: string = 'Users.name', order: string = 'asc', pageNumber: number = 0, pageSize: number = 10, include: string = 'roles,status,users_types,locations,commercial,registration_commercial,manager'): Observable<UsersListResource> {
    return this.http.get(`/${this.resource}/asString`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize) + '&include=' + include)
      .pipe(
        map(users => {
          return UsersListResourceModel.fromJson(users);
        })
      );
  }

  /**
   * Find all items.
   *
   * @returns
   * @memberof RolesService
   */
  findAllRaw(filters: Object = {}, sort: string = 'Users.name', order: string = 'asc', pageNumber: number = 0, pageSize: number = 10, include: string = 'role_name,status_name,user_type_name,location_name,location_code,location_wave_name,commercial_name,registration_commercial_name,manager_name,phases_participations,location_participates_in_phase'): Observable<UsersListResource> {
    return this.http.get(`/${this.resource}/raw`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize) + '&include=' + include)
      .pipe(
        map(users => {
          return UsersListResourceModel.fromJson(users);
        })
      );
  }

  /**
   * Find all users registered for a commercial.
   *
   * @param {number} id
   * @param {Object} [filters={}]
   * @param {string} [sort='Users.name']
   * @param {string} [order='asc']
   * @param {number} pageNumber
   * @param {number} [pageSize=10]
   * @returns {Observable<UsersListResource>}
   * @memberof UsersService
   */
  findAllRegistered(id: number, filters: Object = {}, sort: string = 'Users.name', order: string = 'asc', pageNumber: number, pageSize: number = 10): Observable<UsersListResource> {
    return this.http.get(`/${this.resource}/${id}/registered`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize) + '&include=locations')
      .pipe(
        map(users => {
          return UsersListResourceModel.fromJson(users);
        })
      );
  }

  /**
   * Find all access.
   *
   * @param {Object} [filters={}]
   * @param {string} [sort='Users.name']
   * @param {string} [order='asc']
   * @param {number} pageNumber
   * @param {number} [pageSize=10]
   * @returns {Observable<UsersListResource>}
   * @memberof UsersService
   */
  findAllAccess(filters: Object = {}, sort: string = 'Sessions.created_at', order: string = 'desc', pageNumber: number, pageSize: number = 10): Observable<UsersListResource> {
    return this.http.get(`/${this.resource}/access`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize) + '&include=user')
      .pipe(
        map(users => {
          return UsersListResourceModel.fromJson(users);
        })
      );
  }

  /**
   * Find all user with the role "commercial"
   *
   * @returns {Observable<UsersListResource>}
   * @memberof UsersService
   */
  findAllForCommercials(): Observable<UsersListResource> {
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString({
      'Users.role_id': 3,
      'Users.status_id': StatusCollection.active
    }, 'Users.zone', 'asc', 0, 10000))
      .pipe(
        map(users => {
          return UsersListResourceModel.fromJson(users);
        })
      );
  }

  findAllManagers(): Observable<UsersListResource> {
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString({
      'Users.role_id': 5,
      'Users.status_id': StatusCollection.active
    }, 'Users.name', 'asc', 0, 10000))
      .pipe(
        map(users => {
          return UsersListResourceModel.fromJson(users);
        })
      );
  }

  findAllForWebScope(): Observable<UsersListResource> {
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString({
      'Roles.scope_id': 2,
      'Users.status_id': StatusCollection.active
    }, 'Users.name', 'asc', 0, 10000) + '&include=locations')
      .pipe(
        map(users => {
          return UsersListResourceModel.fromJson(users);
        })
      );
  }

  findAllForCommercialScope(): Observable<UsersListResource> {
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString({
      'Roles.scope_id': ScopesCollection.commercial,
      'Users.status_id': StatusCollection.active
    }, 'Users.name', 'asc', 0, 10000))
      .pipe(
        map(users => {
          return UsersListResourceModel.fromJson(users);
        })
      );
  }

  /**
   * Send activation email.
   * 
   * @param userId number
   */
  sendActivationEmail(userId: number): Observable<any> {
    return this.http.post(`/${this.resource}/${userId}/send-activation`, {});
  }

  /**
   * Find a single item
   *
   * @param {number} id
   * @returns
   * @memberof RolesService
   */
  findOne(id: number, params?: string): Observable<UserResource> {
    return this.http.get(`/${this.resource}/${id}${params ? params : ''}`);
  }

  /**
   * Find a single item
   *
   * @param {number} id
   * @returns
   * @memberof RolesService
   */
  findOneAsString(id: number, params?: string): Observable<UserResource> {
    return this.http.get(`/${this.resource}/asString/${id}${params ? params : ''}`);
  }

  /**
   * Stores an item
   *
   * @param {Role} data
   * @returns
   * @memberof RolesService
   */
  store(data: User): Observable<UserResource> {
    if (data.id) {
      return this.http.put(`/${this.resource}/` + data.id, data);
    } else {
      return this.http.post(`/${this.resource}`, data);
    }
  }

  /**
   * Get the grouped sales of the user in session.
   *
   * @returns {Observable<any>}
   * @memberof UsersService
   */
  getUserGroupedSales(userId: number, qs: string = ''): Observable<any> {
    return this.http.get(`/${this.resource}/${userId}/grouped-sales${qs}`);
  }

  getUserGroupedPointsHistory(userId: number, qs: string = ''): Observable<any> {
    return this.http.get(`/${this.resource}/${userId}/grouped-points-history${qs}`);
  }


  /**
   * Delete one or multiple items.
   * 
   * @param ids 
   */
  delete(ids): Observable<any> {
    return this.http.delete(`/${this.resource}/` + ids.toString());
  }

  getMyProfile() {
    return this.http.get(`/${this.resource}/${this.http.getUserId()}?include=locations`);
  }

  export(filters: Object = {}, sort: string = 'Users.name', order: string = 'asc', pageNumber: number, pageSize: number = 100000) {
    return this.http.get(`/${this.resource}/export`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize));
  }

  exportMembers(filters: Object = {}, emailAddress: string) {
    return this.http.get(`/${this.resource}/export-members`, this.http.buildListQueryString(filters, 'Users.id', 'asc', 0, 1000000) + `&notice_to=${emailAddress}`);
  }
  exportMembersSleep(filters: Object = {}, emailAddress: string) {
    return this.http.get(`/${this.resource}/export-members-sleep`, this.http.buildListQueryString(filters, 'Users.id', 'asc', 0, 1000000) + `&notice_to=${emailAddress}`);
  }

  exportSalesmen(filters: Object = {}, sort: string = 'Users.name', order: string = 'asc', pageNumber: number = 0, pageSize: number = 100000) {
    return this.http.get(`/${this.resource}/export-salesmen`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize));
  }

  exportMembersWithPoints(filters: Object = {}, emailAddress: string) {
    return this.http.get(`/${this.resource}/export-members-with-points`, this.http.buildListQueryString(filters, 'Users.id', 'asc', 0, 1000000) + `&notice_to=${emailAddress}`);
  }

  fetchCommercialOverview(userId: number): Observable<any> {
    return this.http.get(`/${this.resource}/${userId}/commercial-overview`);
  }

  exportMemberPoints(filters: Object = {}, emailAddress: string) {
    return this.http.get(`/${this.resource}/export-member-points`, this.http.buildListQueryString(filters, 'VwWallet.id', 'asc', 0, 1000000) + `&notice_to=${emailAddress}`);
  }

  findAllForAdminScope() {
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString(
      {
        'Roles.scope_id': 1
      }, 'Users.name', 'asc', 0, 10000
    ) + '&include=locations');
  }

  /**
   * Find all users with points in phase
   *
   * @param {number} id
   * @param {Object} [filters={}]
   * @param {string} [sort='Users.email']
   * @param {string} [order='asc']
   * @param {number} pageNumber
   * @param {number} [pageSize=10]
   * @returns {Observable<UsersListResource>}
   * @memberof UsersService
   */
  findAllPointsInPhase(phaseId: number, filters: Object = {}, sort: string = 'Users.email', order: string = 'asc', pageNumber: number, pageSize: number = 10): Observable<UsersListResource> {
    return this.http.get(`/${this.resource}/${phaseId}/users-with-points`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize) + '&include=locations')
      .pipe(
        map(users => {
          return UsersListResourceModel.fromJson(users);
        })
      );
  }

  findPointsHistory(userId, filters: Object = {}, sort: string = 'VwWallet.id', order: string = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<any> {
    return this.http.get(`/${this.resource}/${userId}/points-history`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize));
  }
}
