import { Injectable } from '@angular/core';
import { AuthorizationService, IQueryBuildFilters } from '../authorization.service';
import { Observable } from 'rxjs';
import { PhaseResource, PhasesListResource } from 'src/app/core/interfaces/phases.resource';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StatusService } from '../status/status.service';


export interface Program {
  id: number,
  name: string,
  status_id: number,
  program_id: number,
  starts_at: string
  ends_at: string,
  sales_end_at: string,
  instructions: string,
  picture_id: number,
  challenges_types_id: number,
  phases_types_id: number,
  short_description: string
}

@Injectable({
  providedIn: 'root'
})
export class PhasesService {

  public RANGES_BY_TICKET: string = 'KIT';
  public RANGES_BY_LOCATION: string = 'LOCATION';
  public RANGES_BY_PHASE: string = 'PHASE';

  /**
   * Api resource.
   *
   * @private
   * @type {string}
   * @memberof PhasesService
   */
  private resource: string = 'phases';

  /**
   * Creates an instance of PhasesService.
   *
   * @param {AuthorizationService} http
   * @memberof PhasesService
   */
  constructor(
    private http: AuthorizationService,
    private httpClient: HttpClient,
    private statusService: StatusService
  ) { }

  /**
   * Find all phases.
   *
   * @param {Object} [filters={}]
   * @param {string} [sort='Announcements.name']
   * @param {string} [order='asc']
   * @param {number} pageNumber
   * @param {number} [pageSize=10]
   * @returns {Observable<PhasesListResource>}
   * @memberof PhasesService
   */
  findAll(filters: Object = {}, sort: string = 'Announcements.name', order: string = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<PhasesListResource> {
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize) + '&include=pictures,status,programs,phases_types,challenges_types_id');
  }

  findAllUsersWithPointsLimit(phaseId: number, filters: Object = {}, sort: string = 'Users.email', order: string = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<PhasesListResource> {
    return this.http.get(`/${this.resource}/${phaseId}/users-points-limit`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize) + '&include=locations,users_types');
  }

  findAllUsersWithTicketsLimit(phaseId: number, filters: Object = {}, sort: string = 'Users.email', order: string = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<PhasesListResource> {
    return this.http.get(`/${this.resource}/${phaseId}/users-tickets-limit`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize) + '&include=locations,users_types');
  }

  findMain(): Observable<any> {
    return this.findAll({
      'Phases.accepted_before_month': 1
    });
  }

  /**
   * Find a single phase.
   *
   * @param {number} id
   * @returns {Observable<PhaseResource>}
   * @memberof PhasesService
   */
  findOne(id: number): Observable<PhaseResource> {
    return this.http.get(`/${this.resource}/${id}?include=pictures,header_picture,main_picture,mobile_picture,sharing_pictures,tests_phases,phases_video,phases_units_ranges,phases_amount_intervals`);
  }

  /**
   * Find a single phase as string.
   *
   * @param {number} id
   * @returns {Observable<PhaseResource>}
   * @memberof PhasesService
   */
  findOneAsString(id: number): Observable<PhaseResource> {
    return this.http.get(`/${this.resource}/${id}/asString?include=pictures,header_picture,main_picture,mobile_picture,sharing_pictures,tests_phases,phases_video,phases_units_ranges,phases_amount_intervals`);
  }

  /**
   * Find a single phase as string.
   *
   * @param {number} id
   * @returns {Observable<PhaseResource>}
   * @memberof PhasesService
   */
   findOneSegmentationAsString(id: number): Observable<PhaseResource> {
    return this.http.get(`/${this.resource}/${id}/segmentationAsString`);
  }

  /**
   * Find all assoc products.
   *
   * @param {number} id
   * @returns
   * @memberof PhasesService
   */
  findAllProducts(id: number): Observable<any> {
    return this.http.get(`/${this.resource}/${id}/products`, this.http.buildListQueryString({}, 'name', 'asc', 0, 1000));
  }

  findAllPromotions(id: number, filters: Object = {}, sort: string = 'Promotions.name', order: string = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<any> {
    return this.http.get(`/${this.resource}/${id}/promotions`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize));
  }

  findPromotions(filters: Object = {}, sort: string = 'Promotions.name', order: string = 'asc', pageNumber: number = 0, pageSize: number = 1000): Observable<any> {
    return this.http.get(`/${this.resource}/promotions`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize));
  }

  calculatePromoPoints(promoId, productsIds) {
    return this.http.post(`/${this.resource}/promotions/${promoId}/calculate-points`, productsIds);
  }

  clone(id: number): Observable<any> {
    return this.http.post(`/${this.resource}/${id}/clone`, {});
  }


  /**
   * Get phases for selection.
   *
   * @returns
   * @memberof PhasesService
   */
  getForSelection(filters = {}): Observable<PhasesListResource> {
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString(filters, 'Phases.name', 'asc', 0, 5000));
  }

  /**
   * Get active phases for selection.
   *
   * @returns
   * @memberof PhasesService
   */
  getActiveForSelection(filters = {}): Observable<PhasesListResource> {
    filters = { ...filters, 'Phases.status_id': this.statusService.STATUS_ACTIVE };
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString(filters, 'Phases.name', 'asc', 0, 5000));
  }

  /**
   * Get phases as string for selection.
   *
   * @returns
   * @memberof PhasesService
   */
  getAsStringForSelection(filters = {}): Observable<PhasesListResource> {
    return this.http.get(`/${this.resource}/asString`, this.http.buildListQueryString(filters, 'Phases.name', 'asc', 0, 5000));
  }

  /**
   * Store the phase.
   *
   * @param {Program} data
   * @returns {Observable<PhaseResource>}
   * @memberof PhasesService
   */
  store(data: Program): Observable<PhaseResource> {
    if (data.id) {
      return this.http.put(`/${this.resource}/${data.id}`, data);
    } else {
      return this.http.post(`/${this.resource}`, data);
    }
  }

  /**
   * Delete the given ids.
   *
   * @param {(Array<string> | number)} ids
   * @returns {Observable<any>}
   * @memberof PhasesService
   */
  delete(ids: Array<string> | number): Observable<any> {
    return this.http.delete(`/${this.resource}/${ids.toString()}`)
  }

  /**
   * Get all users that signed up the phase.
   *
   * @param {number} phaseId
   * @param {Object} [filters={}]
   * @param {string} [sort='Announcements.name']
   * @param {string} [order='asc']
   * @param {number} pageNumber
   * @param {number} [pageSize=10]
   * @returns {Observable<any>}
   * @memberof PhasesService
   */
  findAllUsersForSocialSharing(phaseId: number, filters: Object = {}, sort: string = 'PhasesSocialSharing.created_at', order: string = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<any> {
    return this.http.get(`/${this.resource}/${phaseId}/users`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize) + '&include=users');
  }

  /**
   * Find all participants for showcase phases.
   *
   * @param {number} phaseId
   * @param {Object} [filters={}]
   * @param {string} [sort='PhasesShowcase.created_at']
   * @param {string} [order='desc']
   * @param {number} [pageNumber=0]
   * @param {number} [pageSize=10]
   * @returns {Observable<any>}
   * @memberof PhasesService
   */
  findAllUsersForShowcase(phaseId: number, filters: Object = {}, sort: string = 'PhasesShowcase.created_at', order: string = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<any> {
    return this.http.get(`/${this.resource}/${phaseId}/showcase`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize) + '&include=users,locations,pictures');
  }

  /**
   * Export social sharing ranking
   *
   * @param {number} phaseId
   * @returns {Observable<any>}
   * @memberof PhasesService
   */
  exportSocialSharingRanking(phaseId: number): Observable<any> {
    return this.http.get(`/${this.resource}/${phaseId}/users/export`);
  }

  /**
   * Export showcase list.
   *
   * @param {number} phaseId
   * @returns {Observable<any>}
   * @memberof PhasesService
   */
  exportShowcase(phaseId: number): Observable<any> {
    return this.http.get(`/${this.resource}/${phaseId}/showcase/export`);
  }

  /**
   * Fetch ranking data.
   *
   * @param {number} phaseId
   * @param {*} [params={}]
   * @returns {Observable<any>}
   * @memberof PhasesService
   */
  getLocationsRanking(phaseId: number, params = {}): Observable<any> {
    return this.http.get(`/${this.resource}/${phaseId}/locations-ranking`, this.http.parseParams(params));
  }

  /**
   * Export locations ranking.
   *
   * @param {number} phaseId
   * @returns {Observable<any>}
   * @memberof PhasesService
   */
  exportLocationsRanking(phaseId: number): Observable<any> {
    return this.http.get(`/${this.resource}/${phaseId}/locations-ranking/export`);
  }

  /**
   * Find all user´s answers.
   *
   * @param {number} phaseId
   * @param {Object} [filters={}]
   * @param {string} [sort='PhasesUsersCompleted.created_at']
   * @param {string} [order='asc']
   * @param {number} [pageNumber=0]
   * @param {number} [pageSize=10]
   * @returns {Observable<any>}
   * @memberof PhasesService
   */
  findAllTestAnswers(phaseId: number, filters: Object = {}, sort: string = 'PhasesUsersCompleted.created_at', order: string = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<any> {
    return this.http.get(`/${this.resource}/${phaseId}/users-answers`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize) + '&include=questions_and_answers,users');
  }

  /**
   * Users answers export.
   *
   * @param {number} phaseId
   * @returns {Observable<any>}
   * @memberof PhasesService
   */
  exportTestAnswers(phaseId: number): Observable<any> {
    return this.http.get(`/${this.resource}/${phaseId}/users-answers/export`);
  }

  /**
   * Find all users that completed the phase.
   *
   * @param {number} phaseId
   * @param {Object} [filters={}]
   * @param {string} [sort='PhasesUsersCompleted.created_at']
   * @param {string} [order='asc']
   * @param {number} [pageNumber=0]
   * @param {number} [pageSize=10]
   * @returns {Observable<any>}
   * @memberof PhasesService
   */
  findAllPhasesUsersCompleted(phaseId: number, filters: Object = {}, sort: string = 'PhasesUsersCompleted.created_at', order: string = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<any> {
    return this.http.get(`/${this.resource}/${phaseId}/users-completed`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize) + '&include=users');
  }

  /**
   * Export all users that completed the phase.
   *
   * @param {number} phaseId
   * @returns {Observable<any>}
   * @memberof PhasesService
   */
  exportPhasesUsersCompleted(phaseId: number): Observable<any> {
    return this.http.get(`/${this.resource}/${phaseId}/users-completed/export`);
  }

  /**
   * Get sales resume for the given phase.
   *
   * @param {number} phaseId
   * @returns {Observable<any>}
   * @memberof PhasesService
   */
  getSalesResume(phaseId: number): Observable<any> {
    return this.http.get(`/${this.resource}/${phaseId}/sales-resume`);
  }

  /**
   * Get grouped sales for the given phase.
   *
   * @param {number} phaseId
   * @returns {Observable<any>}
   * @memberof PhasesService
   */
  getGroupedSales(phaseId: number): Observable<any> {
    return this.http.get(`/${this.resource}/${phaseId}/grouped-sales`);
  }

  locationsObjetives(data:IQueryBuildFilters): Observable<any> {
    let _data =
    {
      filters: {}, sort: '', order: '', pageNumber: 1, pageSize: 10, include: ''
    }
    data = Object.assign(_data, data);
    return this.http.get(`/${this.resource}/location-objetives`, this.http.buildListQueryString(data.filters, data.sort, data.order, data.pageNumber, data.pageSize) + '&include=' + data.include);
  }

  import(data, phaseId: number): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + this.http.getAccessToken()
      })
    };

    return this.httpClient.post(environment.apiUrl + `/${this.resource}/${phaseId}/import-objetives`, data, httpOptions);
  }

}
