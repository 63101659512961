import { Injectable } from '@angular/core';
import { AuthorizationService } from '../authorization.service';
import { Observable } from 'rxjs';
import { TemplatesListResource, TemplateResource } from 'src/app/core/interfaces/templates.resource';


@Injectable({
  providedIn: 'root'
})
export class TemplatesService {

  /**
   * Api resource
   *
   * @private
   * @type {string}
   * @memberof TemplatesService
   */
  private resource: string = 'notifications/templates';

  /**
   * Creates an instance of TemplatesService.
   * 
   * @param {AuthorizationService} http
   * @memberof TemplatesService
   */
  constructor(
    private http: AuthorizationService
  ) { }

  /**
   * Find templates.
   *
   * @param {Object} [filters={}]
   * @param {string} [sort='NotificationTemplates.name']
   * @param {string} [order='asc']
   * @param {number} pageNumber
   * @param {number} [pageSize=10]
   * @returns {Observable<any>}
   * @memberof TemplatesService
   */
  findAll(filters: Object = {}, sort: string = 'NotificationTemplates.name', order: string = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<TemplatesListResource> {
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize));
  }

  /**
   * Find one item.
   *
   * @param {number} id
   * @returns {Observable<any>}
   * @memberof TemplatesService
   */
  findOne(id: number): Observable<TemplateResource> {
    return this.http.get(`/${this.resource}/${id}`);
  }

  /**
   * Store template data.
   *
   * @param {NotificationTemplate} data
   * @returns {Observable<any>}
   * @memberof TemplatesService
   */
  store(data: any): Observable<any> {
    if (data.id) {
      return this.http.put(`/${this.resource}/` + data.id, data);
    } else {
      return this.http.post(`/${this.resource}`, data);
    }
  }

  /**
   * Delete the given ids.
   *
   * @param {(Array<number> | number)} ids
   * @returns {Observable<any>}
   * @memberof TemplatesService
   */
  delete(ids: Array<number> | number): Observable<any> {
    return this.http.delete(`/${this.resource}/` + ids.toString());
  }

  /**
   * Clone the given ids.
   *
   * @param {(Array<number> | number)} ids
   * @returns {Observable<any>}
   * @memberof TemplatesService
   */
   clone(ids: Array<number> | number): Observable<any> {
    return this.http.get(`/${this.resource}/clone/` + ids.toString());
  }

  /**
   * Set as default the given id.
   *
   * @param {(Array<number> | number)} ids
   * @returns {Observable<any>}
   * @memberof TemplatesService
   */
  setAsDefault(id: number): Observable<TemplateResource> {
    return this.http.get(`/${this.resource}/default/${id}`);
  }

  testEmail(data: any): Observable<any> {
    return this.http.post(`/${this.resource}/test-email`, data);
  }

  /**
   * Find one item.
   *
   * @returns {Observable<any>}
   * @memberof TemplatesService
   */
  findDefault(): Observable<TemplateResource> {
    return this.http.get(`/${this.resource}/default`);
  }
}
