import { ListLinks } from "./list-links";
import { LocationsInterface } from "./locations.resource";

export interface ResponseSegmentationInterface {
    data: DataISegmentation;
}

export interface DataISegmentation {
    total_users: number;
    list: any[];
    total_sent: number;
    total_not_sent: number;
}

export interface UserInterface {
    type: string,
    id: number,
    attributes: {
        commercial_communications: number | null,
        email_communications: number | null,
        created_at: string,
        email: string,
        id: number,
        name: string,
        points: number,
        privacy_policy: number | null,
        recover_hash: string | null,
        role_id: number,
        location_id: number,
        status_id: number,
        surname: string,
        zone: string | null,
        register_hash: string
    },
    include?: {
        locations?: LocationsInterface,
        roles?: any,
        status?: any,
        activation_link?: string,
        commercial?: UserInterface
    },
    links: {
        self: string
    },
    meta: {
        points_by_phase?: any
        has_first_valid_ticket?: {
            readed?: boolean,
            status?: boolean
        }
    }
}

export interface UserResource {
    data: UserInterface
}

export interface UsersListResource {
    data: Array<UserInterface>,
    links: ListLinks
    meta: {
        count: number
        total_users?: number,
        receiving?: number,
    }
}


export class UsersListResourceModel implements UsersListResource {
    data: Array<UserInterface>;
    links: ListLinks;
    meta: {
        count: number;
        total_users?: number;
        receiving?: number,
    };

    constructor() {
        this.data = [];
        this.links = {};
        this.meta = { count: 0, total_users: 0, receiving: 0 };
    }

    public static fromJson(data: any): UsersListResourceModel {
        const model = new UsersListResourceModel();
        model.data = data.data || [];
        model.links = data.links;
        model.meta = data.meta;
        return model;
    }

}

export class ResponseSegmentation implements ResponseSegmentationInterface{
    data: DataISegmentation;

    constructor() {
        this.data = {
            total_users: 0,
            list: [],
            total_sent: 0,
            total_not_sent: 0
        };
    }

    public static fromJson(data: any): ResponseSegmentation {
        const model = new ResponseSegmentation();
        model.data = data.data || [];
        return model;
    }
}