import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UniqueEmailDirective } from './directives/unique-email.directive';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { PaginatorDialogComponent } from './components/paginator-dialog/paginator-dialog.component';
import { LoadingComponent } from './components/loading/loading.component';
import { PreviewImageComponent } from './components/preview-image/preview-image.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { CommonModule } from '@angular/common';
import { SafePipe } from './pipes/safe.pipe';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_FORMATS
} from '@angular/material-moment-adapter';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CdkTableModule } from '@angular/cdk/table';
import { MatButtonModule } from '@angular/material/button';
import { MatPseudoCheckboxModule, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRightSheetModule } from 'mat-right-sheet';
import { SegmentationUsersComponent } from './components/segmentation-users/segmentation-users.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { ProgramSelectionComponent } from './components/program-selection/program-selection.component';
import { CommunitySelectComponent } from './components/community-select/community-select.component';
import { ProductsPointsComponent } from './components/products-points/products-points.component';
import { ProductsAmountComponent } from './components/products-amount/products-amount.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { ChangesRegistryComponent } from './components/changes-registry/changes-registry.component';
import { BtnDownloadUrlComponent } from './components/btn-download-url/btn-download-url.component';
import { AutocompleteSelectorComponent } from './components/autocomplete-selector/autocomplete-selector.component';
import { TemplateEditorComponent } from './components/template-editor/template-editor.component';
import { TemplateEditorDialogComponent } from './components/template-editor-dialog/template-editor-dialog.component';


@NgModule({
  declarations: [
    DatePickerComponent,
    ConfirmDialogComponent,
    PreviewImageComponent,
    LoadingComponent,
    InfoDialogComponent,
    PaginatorDialogComponent,
    UniqueEmailDirective,
    SafePipe,
    SegmentationUsersComponent,
    ProgramSelectionComponent,
    CommunitySelectComponent,
    ProductsPointsComponent,
    ProductsAmountComponent,
    StarRatingComponent,
    ChangesRegistryComponent,
    BtnDownloadUrlComponent,
    AutocompleteSelectorComponent,
    TemplateEditorComponent,
    TemplateEditorDialogComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatPaginatorModule,
    MatTableModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    MatSortModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatSelectModule,
    MatCardModule,
    NgxMatSelectSearchModule,
    MatMenuModule,
    MatAutocompleteModule,
  ],
  exports: [
    ClipboardModule,
    TranslateModule,
    MatButtonModule,
    MatCheckboxModule,
    MatPseudoCheckboxModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    MatToolbarModule,
    FormsModule,
    MatListModule,
    MatSidenavModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    CdkTableModule,
    CdkTreeModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatRadioModule,
    MatRippleModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTabsModule,
    MatTooltipModule,
    MatTreeModule,
    ReactiveFormsModule,
    MatRightSheetModule,
    UniqueEmailDirective,
    FileUploadModule,
    SafePipe,
    NgxChartsModule,
    SegmentationUsersComponent,
    CommunitySelectComponent,
    ProductsPointsComponent,
    ProductsAmountComponent,
    StarRatingComponent,
    ChangesRegistryComponent,
    BtnDownloadUrlComponent,
    AutocompleteSelectorComponent,
    TemplateEditorComponent,
    TemplateEditorDialogComponent
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    {
      provide: MAT_DATE_FORMATS, useValue: {
        parse: {
          dateInput: 'YYYY-MM-DD',
        },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        }
      }
    }
  ]
})

export class SharedModule { }
