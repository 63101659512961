// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  publicLink: 'http://localhost:4200',
  apiUrl: '/api',
  beePlugin: {
    cliend_id: '66b5c7b9-0dbd-48f9-b052-d9de79c17b06',
    client_secret: 'ZjdEi1TB9YXO0WLozY13dtM0oA19kAbD2n1rmZCDs9e3D0Qg3ylR'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
