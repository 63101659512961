import { Injectable } from '@angular/core';
import { AuthorizationService, IQueryBuildFilters } from '../authorization.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LocationsModel } from '../../interfaces/locations.resource';


/**
 * Location interface
 *
 * @export
 * @interface Location
 */
export interface Location {
  id: number,
  code: string,
  name: string,
  address: string
  postal_code: string,
  province_id: number,
  location_id: number,
  city: string,
  phone: string
}

@Injectable({
  providedIn: 'root'
})
export class LocationsService {

  private resource: string = 'locations';

  participatesInPhase: Array<any> = [
    {
      name: "Yes",
      id: true
    },
    {
      name: "No",
      id: false
    },
  ];

  constructor(
    private http: AuthorizationService,
    private httpClient: HttpClient
  ) { }


  /**
   * Find all items.
   *
   * @param {Object} [filters={}]
   * @param {string} [sort='name']
   * @param {string} [order='asc']
   * @param {number} pageNumber
   * @param {number} [pageSize=10]
   * @returns
   * @memberof LocationsService
   */
  findAll(data: IQueryBuildFilters) {
    let defaultOptions = {
      filters: {},
      sort: 'Locations.name',
      order: 'asc',
      pageNumber: 0,
      pageSize: 10,
      include: 'commercial,manager,locations_types,regions,locations_participations_methods,locations_waves,phases_participations'
    }
    data = {
      ...defaultOptions,
      ...data
    };
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString(data.filters, data.sort, data.order, data.pageNumber, data.pageSize) + '&include=' + data.include);
  }

  /**
   * Find all items as string.
   *
   * @param {Object} [filters={}]
   * @param {string} [sort='name']
   * @param {string} [order='asc']
   * @param {number} pageNumber
   * @param {number} [pageSize=10]
   * @returns
   * @memberof LocationsService
   */
  findAllAsString(data: IQueryBuildFilters) {
    let defaultOptions = {
      filters: {},
      sort: 'Locations.name',
      order: 'asc',
      pageNumber: 0,
      pageSize: 10,
      include: 'commercial,manager,locations_types,regions,locations_participations_methods,locations_waves,phases_participations'

    }
    data = {
      ...defaultOptions,
      ...data
    };
    return this.http.get(`/${this.resource}/asString`, this.http.buildListQueryString(data.filters, data.sort, data.order, data.pageNumber, data.pageSize) + '&include=' + data.include);
  }

  /**
   * Find an item.
   *
   * @param {number} id
   * @returns
   * @memberof LocationsService
   */
  findOne(id: number, params?) {
    return this.http.get(`/${this.resource}/${id}${this.http.parseParams(params)}`);
  }

  /**
   * Find an item as string.
   *
   * @param {number} id
   * @returns
   * @memberof LocationsService
   */
  findOneAsString(id: number, params?): Observable<LocationsModel> {
    return this.http.get(`/${this.resource}/${id}/asString${this.http.parseParams(params)}`).pipe(map((data) => {
      return <LocationsModel>Object.assign(new LocationsModel(),{...data.data})
    }));
  }

  /**
   * Gett all locations for selection
   *
   * @returns
   * @memberof LocationsService
   */
  getForSelection(filters = {}) {
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString(filters, 'Locations.name', 'asc', 0, 5000));
  }

  /**
   * Store an item.
   *
   * @param {Location} data
   * @returns
   * @memberof LocationsService
   */
  store(data: Location) {
    if (data.id) {
      return this.http.put(`/${this.resource}/` + data.id, data);
    } else {
      return this.http.post(`/${this.resource}`, data);
    }
  }


  /**
   * Delete one or multiple items.
   *
   * @param {*} ids
   * @returns
   * @memberof LocationsService
   */
  delete(ids) {
    return this.http.delete(`/${this.resource}/${ids.toString()}`)
  }


  /**
   * Import a file with locations.
   *
   * @param {*} data
   * @returns
   * @memberof LocationsService
   */
  import(data) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + this.http.getAccessToken()
      })
    };

    return this.httpClient.post(environment.apiUrl + `/${this.resource}/import`, data, httpOptions);
  }
  /**
   * Import a file with locations to newsletters.
   *
   * @param {*} data
   * @returns
   * @memberof LocationsService
   */
  importNewsletter(data) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + this.http.getAccessToken()
      })
    };

    return this.httpClient.post(environment.apiUrl + `/${this.resource}/import-newsletter`, data, httpOptions);
  }
  /**
   * Import a file with locations delegates.
   *
   * @param {*} data
   * @returns
   * @memberof LocationsService
   */
  importDelegates(data) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + this.http.getAccessToken()
      })
    };

    return this.httpClient.post(environment.apiUrl + `/${this.resource}/import-delegates`, data, httpOptions);
  }

  /**
 * Find all items.
 *
 * @param {Object} [filters={}]
 * @param {string} [sort='name']
 * @param {string} [order='asc']
 * @param {number} pageNumber
 * @param {number} [pageSize=10]
 * @returns
 * @memberof LocationsService
 */
  findLocationsFiltered(data: IQueryBuildFilters) {
    let defaultOptions = { filters: {}, sort: 'Locations.name', order: 'asc', pageNumber: 0, pageSize: 10, include: 'commercial,manager,locations_types,regions,locations_participations_methods,locations_waves,phases_participations' };
    data = { ...defaultOptions, ...data };
    // return this.http.post(`/${this.resource}/filtered`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize) + '&include=' + include);
    let queryParams = this.http.buildListQueryString({}, data.sort, data.order, data.pageNumber, data.pageSize);
    // console.log('queryParams', queryParams);
    return this.http.post(`/${this.resource}/filtered${queryParams}&include=` + data.include, data.filters);
  }

  /**
 * Find all items as string.
 *
 * @param {Object} [filters={}]
 * @param {string} [sort='name']
 * @param {string} [order='asc']
 * @param {number} pageNumber
 * @param {number} [pageSize=10]
 * @returns
 * @memberof LocationsService
 */
  findLocationsAsStringFiltered(data: IQueryBuildFilters) {
    let defaultOptions = { filters: {}, sort: 'Locations.name', order: 'asc', pageNumber: 0, pageSize: 10, include: 'commercial,manager,locations_types,regions,locations_participations_methods,locations_waves,phases_participations' };
    data = { ...defaultOptions, ...data };
    // return this.http.post(`/${this.resource}/filtered`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize) + '&include=' + include);
    let queryParams = this.http.buildListQueryString({}, data.sort, data.order, data.pageNumber, data.pageSize);
    return this.http.post(`/${this.resource}/filtered/asString${queryParams}&include=` + data.include, data.filters);
  }



  /**
   * Find location by code. Called from register.
   *
   * @param {string} code
   * @returns
   * @memberof LocationsService
   */
  findLocationByCode(code: string) {
    return this.httpClient.get(`${environment.apiUrl}/auth/find-location?include=provinces&location_code=${code}`);
  }

  export(filters: Object = {}, emailAddress: string) {
    return this.http.get(`/${this.resource}/export`, this.http.buildListQueryString(filters, 'Sales.id', 'asc', 0, 100000000) + `&notice_to=${emailAddress}`);
  }

  exportLocationsMaxPoints(filters: Object = {}, emailAddress: string) {
    return this.http.get(`/${this.resource}/export-location-max-points`, this.http.buildListQueryString(filters, 'Locations.code', 'asc', 0, 100000000) + `&notice_to=${emailAddress}`);
  }

  exportSegmentationPhase(phaseId: number) {
    return this.http.get(`/${this.resource}/export-segmentation/${phaseId}`, this.http.buildListQueryString({}, 'Sales.id', 'asc', 0, 100000000));
  }

  exportExchangedPoints(filters: Object = {}, emailAddress: string) {
    return this.http.get(`/${this.resource}/export-exchanged-points`, this.http.buildListQueryString(filters, 'Sales.id', 'asc', 0, 100000000) + `&notice_to=${emailAddress}`);
  }

  exportRanking(filters: Object = {}, emailAddress: string) {
    return this.http.get(`/${this.resource}/export-ranking`, this.http.buildListQueryString(filters, 'Locations.name', 'asc', 0, 100000000) + `&notice_to=${emailAddress}`);
  }

  getGroupedSales(locationId: number, qs: string = ''): Observable<any> {
    return this.http.get(`/${this.resource}/${locationId}/grouped-sales${qs}`);
  }

  /**
   * Find all items.
   *
   * @param {Object} [filters={}]
   * @param {string} [sort='name']
   * @param {string} [order='asc']
   * @param {number} pageNumber
   * @param {number} [pageSize=10]
   * @returns
   * @memberof LocationsService
   */
  findAllSegmentation(data: IQueryBuildFilters) {
    let defaultOptions = {
      filters: {},
      sort: 'Locations.name',
      order: 'asc',
      pageNumber: 0,
      pageSize: 10,
      include: 'commercial,manager,locations_types,regions,locations_participations_methods,locations_waves,phases_participations'

    }
    data = {
      ...defaultOptions,
      ...data
    };
    return this.http.get(`/${this.resource}/segmentation/phase`, this.http.buildListQueryString(data.filters, data.sort, data.order, data.pageNumber, data.pageSize) + '&include=' + data.include);
  }
}

