import { Component, OnInit, ChangeDetectorRef, EventEmitter, ViewChild, Output, Input, OnDestroy } from '@angular/core';
import { merge, of as observableOf, Observable, Subscription } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { LocationsService } from 'src/app/core/http/locations/locations.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserInterface, UsersListResource } from 'src/app/core/interfaces/users.resource';
import { ProvincesService } from 'src/app/core/http/provinces/provinces.service';
import { UsersService } from 'src/app/core/http/users/users.service';
import { LocationsTypesService } from 'src/app/core/http/locations-types/locations-types.service';
import { LocationsTypesListResource } from 'src/app/core/interfaces/locations-types.resource';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { RegionsService } from 'src/app/core/http/regions/regions.service';
import { StatusService } from 'src/app/core/http/status/status.service';
import { PhasesService } from 'src/app/core/http/phases/phases.service';
import { UsersTypesService } from 'src/app/core/http/users-types/users-types.service';
import { MatDialog } from '@angular/material/dialog';
import { TemplatesService } from 'src/app/core/http/templates/templates.service';
import { PaginatorDialogComponent } from '../paginator-dialog/paginator-dialog.component';

@Component({
  selector: 'app-segmentation-users',
  templateUrl: './segmentation-users.component.html',
  styleUrls: ['./segmentation-users.component.scss']
})
export class SegmentationUsersComponent implements OnInit, OnDestroy {

  /**
   * Indicates if data is only for reading or to editing.
   *
   * @type {boolean}
   * @memberof AnnouncementUsersComponent
   */
  @Input() readOnlyData: boolean;

  /**
   * Segmentation.
   *
   * @type {*}
   * @memberof AnnouncementsDetailsUsersComponent
   */
  @Input() segmentation: any = {};

  @Input() currentPhaseId: number = 0;

  /**
   * Emit event whe filters change.
   *
   * @memberof AnnouncementsDetailsUsersComponent
   */
  @Output() onFiltersChange = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  /**
   * Selection model for list.
   *
   * @memberof AnnouncementsComponent
   */
  selection = new SelectionModel(true, []);

  /**
   * Length of the results.
   *
   * @type {number}
   * @memberof AnnouncementsComponent
   */
  resultsLength: number = 0;

  /**
   * Indicates ifview is loading results.
   *
   * @type {boolean}
   * @memberof AnnouncementsComponent
   */
  isLoadingResults: boolean = true;

  /**
   * Indicates if view reached to the limit of results.
   *
   * @type {boolean}
   * @memberof AnnouncementsComponent
   */
  isRateLimitReached: boolean = false;

  /**
   * Data source.
   *
   * @memberof AnnouncementsComponent
   */
  dataSource;

  $subs: Subscription[] = [];


  filtersForm: FormGroup = this.formBuilder.group({
    'Locations.location_type_id': [''],
    'Locations.participation_type_id': [''],
    'Locations.region_id': [''],
    'Locations.code': [[]],
    'Locations.phase_id': [''],
    'Locations.city': [[]],
    'Locations.province': [''],
    'Locations.commercial_user_id': [''],
    'Locations.user_type_id': [''],
    'Locations.with_employees': [null],
    'Locations.participates_in_phase': [null],
    'excludedLocationsIds': [[]]
  });

  /**
   * Displayed columns
   *
   * @type {string[]}
   * @memberof PhaseUsersComponent
   */
  displayedColumns: Array<string> = [
    'Locations.name',
    'Locations.code',
    'Locations.province',
    'Locations.city',
    'Locations.total_owners',
    'Locations.total_employees',
    'actions'
  ];

  usersCommercials: Array<UserInterface> = [];

  provinces: Array<any> = [];

  /**
   * Array of locations types.
   *
   * @type {Array<LocationInterface>}
   * @memberof PhaseUsersComponent
   */
  locationsTypes: Array<any>;

  /**
   * Model for location types
   *
   * @type {Array<number>}
   * @memberof AnnouncementUsersComponent
   */
  locationsTypesIds: Array<number>;

  regions: Array<any> = [];
  usersTypes: Array<any> = [];

  participationTypes: Array<any> = this.statusService.getParticipationStatus();

  /**
   * Indicates if is loading results.
   *
   * @type {boolean}
   * @memberof AnnouncementUsersComponent
   */
  isLoading: boolean = false;

  /**
   * Sepearator keys.
   */
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  phases: Array<any> = [];
  stringSearchName: string;
  stringSearchCode: string;

  participatesInPhase: Array<any> = this.locationsService.participatesInPhase;

  /**
   * Creates an instance of AnnouncementsDetailsUsersComponent.
   *
   * @param {RolesService} rolesService
   * @param {UsersService} usersService
   * @param {LocationsTypesService} locationsTypesService
   * @param {ChangeDetectorRef} changeDetector
   * @memberof AnnouncementsDetailsUsersComponent
   */
  constructor(
    private locationsService: LocationsService,
    private locationsTypesService: LocationsTypesService,
    private usersService: UsersService,
    private provincesService: ProvincesService,
    private regionsService: RegionsService,
    private changeDetector: ChangeDetectorRef,
    private statusService: StatusService,
    private formBuilder: FormBuilder,
    private phasesService: PhasesService,
    private usersTypesService: UsersTypesService,
    private dialog: MatDialog,
    private templatesService: TemplatesService,
  ) { }

  ngOnDestroy(): void {
    this.$subs.forEach(s => s.unsubscribe());
  }

  /**
   * On view init.
   *
   * @memberof AnnouncementsDetailsUsersComponent
   */
  ngOnInit() {
    this.fetchLocationsTypes();
    this.fetchCommercials();
    this.fetchProvinces();
    this.fetchRegions();
    if (this.currentPhaseId == 0) {
      this.setupSegmentation();
      this.setupList();
    }
    this.fetchPhases();
    this.fetchUsersTypes();
  }

  fetchUsersTypes(): void {
    let $sub = this.usersTypesService.findAll()
      .subscribe((items: any) => this.usersTypes = items.data);
    this.$subs.push($sub);
  }

  fetchPhases(): void {
    if (this.currentPhaseId !== 0 && this.currentPhaseId !== null) {
      let $sub = this.phasesService.findOneSegmentationAsString(this.currentPhaseId).subscribe((r) => {
        this.segmentation = r['data']['attributes']['segmentation'];
        this.setupSegmentation();
        this.setupList();
      });
      this.$subs.push($sub);
    }

    let $sub2 = this.phasesService.getAsStringForSelection()
      .subscribe((items: any) => this.phases = items.data);
    this.$subs.push($sub2);
  }

  fetchRegions(): void {
    let $sub = this.regionsService.findAllForSelection()
      .subscribe(items => this.regions = items.data);
    this.$subs.push($sub);
  }

  fetchProvinces(): void {
    let $sub = this.provincesService.getForSelection()
      .subscribe(provinces => this.provinces = provinces);
    this.$subs.push($sub);
  }

  getLocationsInSegmentation(): number {
    return this.resultsLength - this.getExcludedLocationsLength()
  }

  /**
   * Fetch locations types.
   *
   * @memberof NotificationSegmentationComponent
   */
  fetchLocationsTypes(): void {
    let $sub = this.locationsTypesService.findAll()
      .subscribe((items: LocationsTypesListResource) => this.locationsTypes = items.data);
    this.$subs.push($sub);
  }

  /**
   * Fetch commercials.
   *
   * @memberof NotificationSegmentationComponent
   */
  fetchCommercials(): void {
    let $sub = this.usersService.findAllForCommercials()
      .subscribe((items: UsersListResource) => {
        this.usersCommercials = items.data;
      },
        (error: any) => {
          console.error(error);
        });
    this.$subs.push($sub);
  }

  /**
   * Setup input segmentation.
   *
   * @memberof AnnouncementsDetailsUsersComponent
   */
  setupSegmentation(): void {
    this.filtersForm.patchValue(this.segmentation);
    this.applyFilters(true, false);
  }

  searchWithString(): void {
    this.applyFilters(false, false);
  }

  clearSearchStringCode(): void {

    this.stringSearchCode = '';
    this.searchWithString();
  }
  clearSearchStringName(): void {

    this.stringSearchName = '';
    this.searchWithString();
  }

  clearFilters(): void {
    let setAsArray = [
      'excludedLocationsIds',
      'Locations.code',
      'Locations.city'
    ];

    Object.keys(this.filtersForm.controls).forEach(key => {
      if (setAsArray.indexOf(key) !== -1) {
        this.filtersForm.controls[key].setValue([]);
      } else {
        this.filtersForm.controls[key].setValue('');
      }
    });

    this.filtersForm.get('excludedLocationsIds').setValue([]);
    this.applyFilters();
  }

  applyFilters(emitChanges: boolean = true, resetExclusions: boolean = true): void {
    this.paginator.pageIndex = 0;

    if (resetExclusions) {
      this.filtersForm.get('excludedLocationsIds').setValue([]);
    }

    this.refresh().subscribe((users: UsersListResource) => this.updateSourceData(users));

    if (emitChanges) {
      this.onFiltersChange.emit(this.filtersForm.value);
    }
  }

  /**
   * Setup list.
   *
   * @memberof AnnouncementsDetailsUsersComponent
   */
  setupList(): void {
    // Set page number to 0 when sorting changes
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // Attach both events
    let $sub = merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.refresh();
        }),
        map(data => {
          // Flip flag to show that loading has finished
          this.isRateLimitReached = false;
          this.resultsLength = data['meta']['count'];

          return data['data'];
        }),
        catchError(() => {

          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => {
        this.isLoadingResults = false;
        this.dataSource = data;
        this.changeDetector.detectChanges();
      });
    this.$subs.push($sub)
  }

  /**
   * Refresh list data.
   *
   * @returns {Observable<any>}
   * @memberof AnnouncementsComponent
   */
  refresh(): Observable<any> {
    this.isLoadingResults = true;
    let filters = this.filtersForm.getRawValue();

    filters.excludedLocationsIds = [];

    if (this.stringSearchCode) {
      filters['Locations.code_str'] = this.stringSearchCode;
    }
    if (this.stringSearchName) {
      filters['Locations.name'] = this.stringSearchName;
    }
    return this.locationsService.findLocationsAsStringFiltered({
      filters,
      sort: this.sort.active,
      order: this.sort.direction,
      pageNumber: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize == undefined ? 10 : this.paginator.pageSize
    });
  }

  /**
   * Update source data.
   *
   * @param {*} data
   * @memberof AnnouncementsComponent
   */
  updateSourceData(data): void {
    this.isLoadingResults = false;
    this.isRateLimitReached = false;
    this.resultsLength = data['meta']['count'];
    this.dataSource = data['data'];

    // const excludedLocationsIds = this.filtersForm.get('excludedLocationsIds').value;
    // const resultFilter = excludedLocationsIds.filter((element)=>{
    //   const index = data['data'].find(item=>item.id == element);
    //   return index != undefined;
    // });
    // this.filtersForm.get('excludedLocationsIds').setValue(resultFilter);

    this.changeDetector.detectChanges();
  }

  /**
   * Find users from filters
   *
   * @memberof PhaseUsersComponent
   */
  findItems(): void {
    this.isLoading = true;

    // Emit event
    this.onFiltersChange.emit(this.filtersForm.value);

    // Update source data
    let $sub = this.refresh().subscribe((locations: any) => this.updateSourceData(locations));
  }

  /**
   * Toggle all locations.
   *
   * @param {*} ev
   * @memberof AnnouncementUsersComponent
   */
  toggleAllLocationsTypes(ev) {
    if (ev.selected) {
      this.locationsTypesIds = [];
      this.locationsTypes.forEach(el => this.locationsTypesIds.push(el.id));
      this.locationsTypesIds.push(-1);
    }

    if (ev.selected == false) {
      this.locationsTypesIds = [];
    }
  }

  /**
   * Indicates if the given user id is inthe excluded users stack.
   *
   * @param {number} userId
   * @returns {boolean}
   * @memberof NotificationSegmentationComponent
   */
  isLocationExcluded(userId: number): boolean {
    return this.filtersForm.get('excludedLocationsIds').value.indexOf(userId) !== -1;
  }


  /**
   * Get length of the excluded users.
   *
   * @returns {number}
   * @memberof NotificationSegmentationComponent
   */
  getExcludedLocationsLength(): number {
    return this.filtersForm.get('excludedLocationsIds').value.length;
  }

  /**
   * Toggle user id from exclusion stack.
   *
   * @param {number} userId
   * @memberof NotificationSegmentationComponent
   */
  toggleLocationExclusion(user: any): void {
    let values = this.filtersForm.get('excludedLocationsIds').value;

    let index: number = values.indexOf(user.id);

    if (index === -1) {
      values.push(user.id);
    } else {
      values.splice(index, 1);
    }


    this.filtersForm.get('excludedLocationsIds').setValue(values);
    this.onFiltersChange.emit(this.filtersForm.value);
  }


  add(event: MatChipInputEvent, formKey: string): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      let values = this.filtersForm.controls[formKey].value;
      values = Array.isArray(values) ? values : [];
      values.push(value.trim());
      this.filtersForm.controls[formKey].setValue(values);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(item: any, formKey: string): void {
    const values = this.filtersForm.controls[formKey].value;
    const index = values.indexOf(item);

    if (index >= 0) {
      values.splice(index, 1);
    }

    this.filtersForm.controls[formKey].setValue(values);

  }

  pasteLocationsCodes(event: ClipboardEvent, formKey: string): void {
    event.preventDefault(); //Prevents the default action
    event.clipboardData
      .getData('Text') //Gets the text pasted
      .split(/;|,|\n/) //Splits it when a SEMICOLON or COMMA or NEWLINE
      .forEach(value => {
        if ((value || '').trim()) {
          let values = this.filtersForm.controls[formKey].value;
          values = Array.isArray(values) ? values : [];
          values.push(value.trim());
          this.filtersForm.controls[formKey].setValue(values);
        }
      });
  }


  showExcludedList(): void {
    this.locationsService.findLocationsFiltered({
      filters: {
        'Locations.id': this.filtersForm.get('excludedLocationsIds').value.join(',')
      },
      sort: this.sort.active,
      order: this.sort.direction,
      pageNumber: 0,
      pageSize: 10000
    })
      .subscribe((items: any) => {
        this.dialog.open(PaginatorDialogComponent, {
          data: {
            title: 'Estancos excluídos',
            content: items,
            attributes: ['code', 'name']
          }
        })
      });
  }

  export() {
    this.isLoadingResults = true;
    this.usersService.getMyProfile()
      .subscribe((user: any) => {
        this.locationsService.exportSegmentationPhase(
          this.currentPhaseId
        ).subscribe(r => {

          this.isLoadingResults = false;
          this.changeDetector.detectChanges();
          if (r['data']['file']) {
            this.templatesService.downloadTmpFile(r['data']['file']);
          }
        }, error => {
          console.log(error);
          this.isLoadingResults = false;
        })
      });
  }
}

