import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, Subject, of, throwError } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AuthorizationService } from 'src/app/core/http/authorization.service';

interface CacheItem {
  [key: string]: Item;
}
interface Item {
  id: number;
  name: string;
}

@Component({
  selector: 'app-autocomplete-selector',
  templateUrl: './autocomplete-selector.component.html',
  styleUrls: ['./autocomplete-selector.component.scss']
})
export class AutocompleteSelectorComponent implements OnInit, OnChanges {
  @Input() url: string = '/provinces?filter[Phases.name]:=';
  @Input() modelKey: string = 'province';
  @Input() resultKey: string = 'data';
  @Input() selectedValuesInit: Item[] = [];

  @Input() label: string;
  @Input() programId: number;
  @Output() selectedValuesChange = new EventEmitter<Item[]>();

  cache: { [key: string]: CacheItem } = {};
  inputValue = '';
  selectedValues: Item[] = [];
  searchValue = ''
  hasErrorComponent: boolean = false;
  errorResult: string = '';


  filteredOptions: Observable<Item[]>;
  private destroy$ = new Subject<void>();

  constructor(private http: AuthorizationService, private changeDetector: ChangeDetectorRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedValuesInit && changes.selectedValuesInit.currentValue !== changes.selectedValuesInit.previousValue) {
      this.selectedValues = this.selectedValuesInit;
    }
  }

  ngOnInit(): void {
    this.filteredOptions = of([]);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  inputValueChanged(value: string): void {
    this.filteredOptions = this.getOptions(value.toLowerCase());
  }

  getOptions(value: string): Observable<{ id: number, name: string }[]> {
    if (this.cache[value]) {
      const options = Object.keys(this.cache[value]).map(key => {
        const item = this.cache[value][key];
        this.searchValue = value;
        return { id: item.id, name: item[this.modelKey] };
      });
      return of(options);
    } else {
      return this.http.get(`${this.url}${value}`).pipe(
        takeUntil(this.destroy$),
        map(r => {
          this.hasErrorComponent = false;
          this.errorResult = '';
          return r[this.resultKey] ?? [];
        }),
        switchMap(result => of(result.reduce((acc, item) => {
          let obj = this.findKey(item, 'id', this.modelKey);
          acc[obj.name] = obj;
          return acc;
        }, {}))),
        tap(result => {
          this.searchValue = value;
          return this.cache[value] = result
        }),
        map(result => Object.keys(result).map(key => {
          const item = result[key];
          return { id: item.id, name: item[this.modelKey] };
        })),
        catchError(error => {
          this.hasErrorComponent = true;
          this.errorResult = error.message ? error.message : 'Not handler error';
          this.changeDetector.detectChanges()
          return throwError(error);
        })

      );
    }
  }


  private findKey(obj: any, key1: string, key2: string): Item {
    if (obj && typeof obj === 'object') {
      if (key1 in obj && key2 in obj) {
        return { id: obj[key1], name: obj[key2] };
      }
      for (const k in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, k)) {
          const result = this.findKey(obj[k], key1, key2);
          if (result !== undefined) {
            return result;
          }
        }
      }
    }
  }


  displayFn(value: string): string {
    return value ? value : '';
  }


  optionSelected(event: MatAutocompleteSelectedEvent): void {
    const selectedValue = event.option.value;
    const item = this.cache[this.searchValue][selectedValue];
    if (item && !this.selectedValues.some(v => v.id === item.id && v.name === item[this.modelKey])) {
      this.selectedValues.push({ id: item.id, name: item[this.modelKey] });
      this.selectedValuesChange.emit(this.selectedValues);
      this.inputValue = '';
    }
  }

  removeValue(value: { id: number, name: string }): void {
    const index = this.selectedValues.findIndex(v => v.id === value.id);
    if (index >= 0) {
      this.selectedValues.splice(index, 1);
      this.selectedValuesChange.emit(this.selectedValues);
    }
  }


}
