import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, of as observableOf, Observable } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { ChangesRegistryService } from 'src/app/core/http/changes-registry/changes-registry.service';
import { UsersService } from 'src/app/core/http/users/users.service';
import { ChangesRegistryListResource } from 'src/app/core/interfaces/changes-registry.resource';

@Component({
  selector: 'app-changes-registry',
  templateUrl: './changes-registry.component.html',
  styleUrls: ['./changes-registry.component.css']
})
export class ChangesRegistryComponent implements OnInit {

  /**
   * Source 
   *
   * @type {string}
   * @memberof ChangesRegistryComponent
   */
  @Input() sourceName: any;

  /**
   * Source identifier.
   *
   * @type {*}
   * @memberof ChangesRegistryComponent
   */
  @Input() sourceId: any;

  /**
   * Paginator reference.
   *
   * @type {MatPaginator}
   * @memberof ChangesRegistryComponent
   */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  /**
   * Sort reference.
   *
   * @type {MatSort}
   * @memberof ChangesRegistryComponent
   */
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  /**
   * Result´s length
   *
   * @type {number}
   * @memberof ChangesRegistryComponent
   */
  resultsLength: number = 0;

  /**
   * Indicates if component is loading results.
   *
   * @type {boolean}
   * @memberof ChangesRegistryComponent
   */
  isLoadingResults: boolean = true;

  /**
   * Indicates if list has reached to limit.
   *
   * @memberof ChangesRegistryComponent
   */
  isRateLimitReached: boolean = false;

  /**
   * Data source.
   *
   * @memberof ChangesRegistryComponent
   */
  dataSource;

  /**
   * Displayed columns in the table.
   *
   * @type {Array<string>}
   * @memberof ChangesRegistryComponent
   */
  displayedColumns: Array<string> = [
    'ChangesRegistry.modified_by',
    'ChangesRegistry.created_at',
    'ChangesRegistry.column_name',
    'ChangesRegistry.previous_value',
    'ChangesRegistry.current_value'
  ];


  filters = {};
  filtersCount;
  filtersForm;
  filtersValues = {
    name: null
  };

  users: Array<any> = [];

  constructor(
    private changesRegistryService: ChangesRegistryService,
    private usersService: UsersService,
    private changeDetector: ChangeDetectorRef
  ) { }


  /**
   * On view init.
   *
   * @memberof ChangesRegistryComponent
   */
  ngOnInit(): void {
    this.fetchUsers();
    this.setupList();
  }


  /**
   * Fetch users.
   *
   * @memberof ChangesRegistryComponent
   */
  fetchUsers(): void {
    this.usersService.findAllForAdminScope()
      .subscribe((users: any) => this.users = users.data);
  }

  /**
   * Set up list events
   *
   * @memberof ChangesRegistryComponent
   */
  setupList(): void {
    // Set page number to 0 when sorting changes
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // Attach both events
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.refresh();
        }),
        map(data => {
          // Flip flag to show that loading has finished
          this.isRateLimitReached = false;
          this.resultsLength = data['meta']['count'];

          return data['data'];
        }),
        catchError(() => {

          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => {
        this.isLoadingResults = false;
        this.dataSource = data;
      });
  }

  /**
   * Refresh the data.
   *
   * @returns {Observable<any>}
   * @memberof ChangesRegistryComponent
   */
  refresh(): Observable<ChangesRegistryListResource> {
    this.isLoadingResults = true;
    this.filters['ChangesRegistry.source_name'] = this.sourceName;
    this.filters['ChangesRegistry.source_id'] = this.sourceId;
    
    return this.changesRegistryService.findAll(this.filters,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  /**
   * Update source data.
   *
   * @param {*} data
   * @memberof ChangesRegistryComponent
   */
  updateSourceData(data: any): void {
    this.isLoadingResults = false;
    this.isRateLimitReached = false;
    this.resultsLength = data.meta.count;
    this.dataSource = data.data;
    this.changeDetector.detectChanges();
  }

  /**
   * Refrech list.
   *
   * @memberof ChangesRegistryComponent
   */
  refreshList(): void {
    this.refresh().subscribe(data => this.updateSourceData(data));
  }


  /**
   * Apply filters to list.
   *
   * @memberof CategoriesComponent
   */
  applyFilters(): void {
    this.filtersCount = 0;
    this.paginator.pageIndex = 0;
    this.filters = {};

    for (let filter of Object.keys(this.filtersValues)) {
      if (this.filtersValues[filter]) {
        this.filters[filter] = this.filtersValues[filter];
        this.filtersCount++;
      }
    }

    this.filtersCount = this.filtersCount == 0 ? null : this.filtersCount;
    this.refreshList();
  }

  /**
   * Clear the filters.
   *
   * @memberof CategoriesComponent
   */
  clearFilters(): void {
    this.filtersCount = null;
    this.filters = {};
    for (let filter of Object.keys(this.filtersValues)) {
      this.filtersValues[filter] = null;
    }

    this.refreshList();
  }
}
