import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TemplatesService } from 'src/app/core/http/notifications/templates.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-template-editor',
  templateUrl: './template-editor.component.html',
  styleUrls: ['./template-editor.component.scss']
})
export class TemplateEditorComponent implements OnInit, OnChanges {

  @Input() content = '';
  @Input() loadedTemplate = null;
  @Output() contentEvent = new EventEmitter();
  @Output() htmlEvent = new EventEmitter();

  defaultTemplate = {};

  constructor(
    private templatesService: TemplatesService,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.fetchTemplate();
  }

  ngOnChanges(): void {
    if (this.loadedTemplate != null) {
      this.initEditor(this.contentEvent, this.loadedTemplate, this.htmlEvent, this.defaultTemplate, this.snackBar);
    }
  }

  fetchTemplate() {
    const filters = { 'NotificationsTemplates.is_default': 1 };

    this.templatesService.findAll(filters)
      .subscribe(r => {
        if (r.data.length > 0) {
          const defaultTemplate = r['data'][0]['attributes'];

          this.defaultTemplate = { template: defaultTemplate['template'], content: defaultTemplate['content'] };
        }
      }, error => {
        console.log(error);
      }, () => {
        this.initEditor(this.contentEvent, this.content, this.htmlEvent, this.defaultTemplate, this.snackBar);
      });
  }

  initEditor(contentEvent, _templateContent, htmlEvent, _defaultTemplate, snackBar) {
    //@ts-ignore
    "use strict"; !function (e) { if (!window.BeePlugin) { var t = function () { var e = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"; return Array.apply(null, Array(50)).map(function () { return e[Math.floor(Math.random() * e.length)] }).join("") }, n = e.createElement("script"); n.type = "text/javascript", n.src = "https://loader.getbee.io/v1/api/loader?v=" + t(), e.getElementsByTagName("head")[0].appendChild(n); var r = {}; r._queue = []; for (var a = "create".split(","), u = function (e, t) { return function () { t.push([e, arguments]) } }, i = 0; i < a.length; i++)r[a[i]] = u(a[i], r._queue); window.BeePlugin = r } }(document);

    let templateContent = _templateContent;
    let defaultTemplate = _defaultTemplate;

    var request = function (method, url, data, type, callback) {
      var req = new XMLHttpRequest();
      req.onreadystatechange = function () {
        if (req.readyState === 4 && req.status === 200) {
          var response = JSON.parse(req.responseText);
          callback(response);
        } else if (req.readyState === 4 && req.status !== 200) {
          console.error('Access denied, invalid credentials. Please check you entered a valid client_id and client_secret.');
        }
      };

      req.open(method, url, true);
      if (data && type) {
        if (type === 'multipart/form-data') {
          var formData = new FormData();
          for (var key in data) {
            formData.append(key, data[key]);
          }
          data = formData;
        }
        else {
          req.setRequestHeader('Content-type', type);
        }
      }
      req.send(data);
    };

    var mergeTags = [{
      name: 'ID del usuario',
      value: '{{id}}'
    }, {
      name: 'Nombre del usuario',
      value: '{{name}}'
    }, {
      name: 'Apellidos del usuario',
      value: '{{surname}}'
    }, {
      name: 'Email del usuario',
      value: '{{email}}'
    }, {
      name: 'Baja de comunicaciones',
      value: '{{unsubscribeLink}}'
    }];

    function userInput(message, sample) {
      return function handler(resolve, reject) {
        var data = prompt(message, JSON.stringify(sample))
        return (data == null || data == '')
          ? reject()
          : resolve(JSON.parse(data))
      }
    }

    var beeConfig = {
      uid: 'test1-clientside',
      container: 'bee-plugin-container',
      autosave: 3,
      language: 'es-ES',
      trackChanges: true,
      mergeTags: mergeTags,
      onSave: function (jsonFile, htmlFile) {
        const template = { json: jsonFile, html: htmlFile };
        contentEvent.emit(template);
      },
      onSaveAsTemplate: function (jsonFile) {
        const template = { json: jsonFile, html: null };
        contentEvent.emit(template);
      },
      onAutoSave: function (jsonFile) {
        localStorage.setItem('editor-template.autosave', jsonFile);
      },
      onSend: function (htmlFile) {
        snackBar.open('Para enviar una prueba escribe el correo electrónico del destinatario en el campo bajo el titular “Enviar test”', 'OK', {
          duration: 5000,
          verticalPosition: 'top',
          panelClass: 'success'
        });
      },
      onError: function (errorMessage) {
        console.log('onError ', errorMessage);
      }
    };

    var bee = null;

    request(
      'POST',
      'https://auth.getbee.io/apiauth',
      `grant_type=password&client_id=${environment.beePlugin.cliend_id}&client_secret=${environment.beePlugin.client_secret}`,
      'application/x-www-form-urlencoded',
      function (token) {
        // @ts-ignore
        BeePlugin.create(token, beeConfig, function (beePluginInstance) {
          
          bee = beePluginInstance;

          var template = defaultTemplate.template;

          if (templateContent !== '' && templateContent !== null && templateContent !== undefined) {
            template = JSON.parse(templateContent);
          } else {
            htmlEvent.emit(defaultTemplate.content);
          }

          bee.start(template);
        });
      });
  }
}
