import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-paginator-dialog',
  templateUrl: './paginator-dialog.component.html',
  styleUrls: ['./paginator-dialog.component.scss']
})
export class PaginatorDialogComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource = [];
  resultsLength = 0;

  constructor(
    public dialogRef: MatDialogRef<PaginatorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.resultsLength = this.data.content.meta.count;
    const paginatorEvent = {
      length: this.resultsLength,
      pageIndex: this.paginator.pageIndex,
      pageSize: 10
    }
    this.paginatorChange(paginatorEvent);
  }

  paginatorChange(event) {
    // Clear data source
    this.dataSource = [];
    // Set start index
    const start = event.pageIndex * event.pageSize;
    // Set end index - If the default operation is bigger than the array length then set array length as index
    const end = (start + event.pageSize) > event.length ? event.length : start + event.pageSize;

    for (let index = start; index < end; index++) {
      const element = this.data.content.data[index];
      
      let item = '';
      this.data.attributes.forEach((attribute, i) => {
        item += i > 0 ? `${element.attributes[attribute]} ` : `[${element.attributes[attribute]}] `;
      });
      this.dataSource.push(item);
    }
  }

  close() {
    this.dialogRef.close();
  }

}
