import { Injectable } from '@angular/core';
import { AuthorizationService } from '../authorization.service';
import { Observable } from 'rxjs';
import { ChangesRegistryListResource } from '../../interfaces/changes-registry.resource';

@Injectable({
  providedIn: 'root'
})
export class ChangesRegistryService {

  /**
   * Resource name.
   *
   * @private
   * @type {string}
   * @memberof ChangesRegistryService
   */
  private resource: string = 'changes-registry';

  constructor(
    private http: AuthorizationService
  ) { }


  /**
   * Find all items
   *
   * @param {Object} [filters={}]
   * @param {string} [sort='ChangesRegistry.created_at']
   * @param {string} [order='desc']
   * @param {number} [pageNumber=0]
   * @param {number} [pageSize=10]
   * @returns {Observable<ChangesRegistryListResource>}
   * @memberof ChangesRegistryService
   */
  findAll(filters: Object = {}, sort: string = 'ChangesRegistry.created_at', order: string = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<ChangesRegistryListResource> {
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize));
  }
}
