import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProgramsService } from 'src/app/core/http/programs/programs.service';

@Component({
  selector: 'app-program-selection',
  templateUrl: './program-selection.component.html',
  styleUrls: ['./program-selection.component.css']
})
export class ProgramSelectionComponent implements OnInit {

  programs: Array<any> = [];

  constructor(
    private programsService: ProgramsService,
    public dialogRef: MatDialogRef<ProgramSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    console.log(this.data);
    this.programsService.findAll()
      .subscribe((items: any) => this.programs = items.data);
  }

  select(program): void {
    this.dialogRef.close(program);
  }

  close(): void {
    this.dialogRef.close(false);
  }

}
