import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from 'src/app/shared/components/info-dialog/info-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ErrorsService {

  /**
   * Creates an instance of ErrorsService.
   * 
   * @param {MatDialog} dialog
   * @memberof ErrorsService
   */
  constructor(
    private dialog: MatDialog
  ) { }


  /**
   * Show error.
   *
   * @param {ErrorsInterface} error
   * @memberof ErrorsService
   */
  showError(error: any): void {

    const messages = [];
    if (error.errors.length > 0) {
      error.errors.forEach((item: any) => {
        messages.push(`[${item.title}] ${item.detail ? item.detail : ''}`)
      })
    }

    this.dialog.open(InfoDialogComponent, {
      data: {
        title: 'common.anErrorHasOcurred',
        content: messages.join('<br>')
      }
    })
  }
}
