import { ListLinks } from "./list-links";

export interface LocationsInterface {
    type: string,
    id: number,
    attributes: LocationsAttributes,
    links: {
        self: string
    },
    include?: any,
    meta: LocationsMeta
}

export interface LocationsMeta {
    distributor_user_id: number,
    total_available_points: number,
    total_points: number,
    total_sales: number,
    total_units: number,
    total_users: number,
    total_net_sales: number | null
}
export interface LocationsAttributes {
    id: number,
    name: string,
    address: string,
    city: string
    code: string,
    phone: string
    postal_code: string,
    sales_objective: number | null,
    province_id: number
    status_id: number
    created_at: string
    can_register_employees: number
    participation_type_id: number
    participates_in_phase: number
    region_id: number
    wave_id: number
}

export interface LocationsResource {
    data: LocationsInterface
}

export interface LocationsListResource {
    data: Array<LocationsInterface>,
    links: ListLinks
    meta: {
        count: number
    }
}

export class LocationsModel implements LocationsInterface {
    meta: LocationsMeta;
    _attributes: LocationsAttributes;
    type: string;
    id: number;
    links: { self: string; };
    include?: any;

    
    public set attributes(v: LocationsAttributes) {
        v.can_register_employees = +v.can_register_employees;
        v.participates_in_phase = +v.participates_in_phase;
        v.participation_type_id = +v.participation_type_id;
        v.region_id = +v.region_id;
        v.wave_id = +v.wave_id;
        this._attributes = {...v};
    }
    
    public get attributes() : LocationsAttributes {
        return this._attributes;
    }
    
    

}